<template>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/5.0.0/normalize.min.css">
  <nav class="navbar">
    <div class="container-fluid">
      <a class="navbar-brand">
        <img class="img-fluid" src="../assets/logo.svg" alt="" @click="goHome">
      </a>

      <div v-if="isSignedIn">
        <div class="d-flex">
          <a role="button" class="header-btn" @click="doSignOut">Sign Out</a>
        </div>
      </div>
      <div v-else>
        <div class="d-flex">
          <a role="button" class="header-btn" @click="doSignIn">Sign In</a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { defineComponent } from "vue";
import AuthService from "@/services/auth-service";

export default defineComponent({
  name: 'Header',
  data () {
    return {
      pageName: ''
    }
  },
  created() {
    this.pageName = this.$route.meta.pageName
  },
  methods: {
    doSignIn () {
      this.$router.push('/signin')
    },
    goHome () {
      this.$router.push('/')
    },
    doAccount () {
      this.$router.push('/account')
    },
    doHelp () {
      this.$router.push('/help')
    },
    doSignOut () {
      AuthService.signOut().then(() => {
        this.$router.push('/signin')
      })
    },
  },
  computed: {
    isSignedIn () {
      return AuthService.isSignedIn()
    }
  }
})
</script>

<style>
@import '../assets/style.css';
</style>
