import apiClient from '@/utils/api-client'

import {
    SignupRequest,
    UserProfileResponse,
    Plan,
    ContactFormRequest,
    PasswordRequest,
    UserLatestResponse,
    CouponResponse,
    Device,
    Dynamic
} from "@/model/models";
class UsersService {
    public getUserProfile() {
        return new Promise<boolean>((resolve, reject) => {
            apiClient.get('/api/v1/user/profile').then((res) => {
                const userProfile: UserProfileResponse
                    = new UserProfileResponse(res.data.userId, res.data.email, res.data.active, res.data.status, res.data.region, res.data.videoQuality,
                    res.data.planId, res.data.planStartDate, res.data.planEndDate, res.data.planActive, res.data.planStatus,
                    res.data.planCreated, res.data.planUpdated, res.data.trialOverallTime, res.data.trialTodayTime)
                localStorage.setItem('userProfile', JSON.stringify(userProfile))

                const planList = JSON.parse(localStorage.getItem('planList') || '{}')

                for (const x in planList) {
                    if (planList[x].planId == userProfile.planId) {
                        const userPlan = new Plan(planList[x].planId, planList[x].planName, planList[x].description, planList[x].maxDevices, planList[x].duration,
                            planList[x].orderNumber, planList[x].price, planList[x].active, planList[x].status, planList[x].trial, planList[x].created, planList[x].updated)
                        localStorage.setItem('userPlan', JSON.stringify(userPlan))
                    }
                }
                resolve(true)
            }).catch(() => {
                reject(false)
            })
        })
    }

    public validateUserEmailRequest(email: string) {
        return new Promise<boolean>((resolve, reject) => {
            apiClient.get('/api/v1/user/email/' + email).then((res) => {
                resolve(res.data)
            }).catch(() => {
                reject(false)
            })
        })
    }

    public signupUserRequest(signupRequest: SignupRequest) {
        return new Promise<boolean>((resolve, reject) => {
            apiClient.post('/api/v1/auth/signup', signupRequest).then((res) => {
                if (res.status === 200 && res.data !== null) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            }).catch((error) => {
                reject(error)
            })
        })
    }

    public contactFormRequest(contactFormRequest: ContactFormRequest) {
        return new Promise<boolean>((resolve, reject) => {
            apiClient.post('/api/v1/user/contact-form', contactFormRequest).then((res) => {
                if (res.status === 200 && res.data !== null) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            }).catch((error) => {
                reject(error)
            })
        })
    }

    public changePasswordRequest(passwordRequest: PasswordRequest) {
        return new Promise<boolean>((resolve, reject) => {
            apiClient.put('/api/v1/auth/password', passwordRequest).then((res) => {
                resolve(res.data)
            }).catch((error) => {
                reject(error)
            })
        })
    }

    public logoutFromAllDevices() {
        return new Promise<string>((resolve, reject) => {
            apiClient.get('/api/v1/user/logout').then((res) => {
                // fetch userLatest
                if (res.status === 200 && res.data !== null) {
                    resolve(res.data)
                } else {
                    resolve("")
                }
            }).catch(() => {
                reject(false)
            })
        })
    }

    public getUserLatest() {
        return new Promise<UserLatestResponse>((resolve, reject) => {
            apiClient.get('/api/v1/user/latest').then((res) => {
                // fetch userLatest
                if (res.data !== null) {
                    const newUserLatest: UserLatestResponse
                        = new UserLatestResponse(res.data.userProfile, res.data.userPlans, res.data.payments, res.data.channels, res.data.statics, res.data.loginId, res.data.errorText)
                    if (res.data && res.data.sk) {
                        localStorage.setItem('sk', res.data.sk);
                    }
                    resolve(newUserLatest)
                } else {
                    reject(false)
                }
            }).catch(() => {
                reject(false)
            })
        })
    }

    public validateCoupon(coupon: string) {
        return new Promise<CouponResponse>((resolve, reject) => {
            apiClient.get('/api/v1/coupon/validate/' + coupon).then((res) => {
                if (res.status === 200 && res.data !== null) {
                    const couponResponse = new CouponResponse(res.data.valid, res.data.couponCode, res.data.percentage)
                    resolve(couponResponse)
                } else {
                    reject(false)
                }
            }).catch((error) => {
                reject(error)
            })
        })
    }
    public insertDevice(Device: Device) {
        return new Promise<boolean>((resolve, reject) => {
            apiClient.post('/api/v1/user/device', Device).then((res) => {
                if (res.status === 200 && res.data !== null) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            }).catch((error) => {
                reject(error)
            })
        })
    }

    public getUserStaticData() {
        return new Promise<boolean>((resolve, reject) => {
            apiClient.get('/api/v1/user/static').then((res) => {
                if (res.status === 200 && res.data !== null) {
                    // set plans
                    const list: Plan[] = [];
                    const planList = res.data.planList
                    for (const x in planList) {
                        if (planList[x].active) {
                            list.push(new Plan(planList[x].planId, planList[x].planName, planList[x].description, planList[x].maxDevices, planList[x].duration,
                                planList[x].orderNumber, planList[x].price, planList[x].active, planList[x].status, planList[x].trial, planList[x].created, planList[x].updated));
                        }
                    }
                    list.sort((n1, n2) => {
                        if (n1.planName > n2.planName) {
                            return 1;
                        }
                        if (n1.planName < n2.planName) {
                            return -1;
                        }
                        return 0;
                    });

                    list.sort((n1, n2) => {
                        if (n1.orderNumber > n2.orderNumber) {
                            return 1;
                        }
                        if (n1.orderNumber < n2.orderNumber) {
                            return -1;
                        }
                        return 0;
                    });
                    localStorage.setItem('planList', JSON.stringify(list))
                    // set regions
                    const newRegions:Record<string, string> = { "null": "Automatic" };
                    for (const [key, value] of Object.entries(res.data.regions)) {
                        if (key !== "null") {
                            if (typeof value === "string") {
                                newRegions[key] = value;
                            }
                        }
                    }
                    localStorage.setItem('regions', JSON.stringify(newRegions))
                    // set videoQualities
                    localStorage.setItem('videoQualityList', JSON.stringify(res.data.videoQualities))
                    resolve(true)
                } else {
                    resolve(false)
                }
            }).catch((error) => {
                reject(error)
            })
        })
    }
    public getDynamicData() {
        return new Promise<boolean>((resolve, reject) => {
            apiClient.get('/api/v1/user/dynamic').then((res) => {
                const dynamicData: Dynamic = new Dynamic(res.data.edgeAddress, res.data.pk, res.data.vidictId, res.data.videoQuality)
                localStorage.setItem('dynamicData', JSON.stringify(dynamicData))
                resolve(res.data)
            }).catch(() => {
                reject(false)
            })
        })
    }
}

export default new UsersService()
