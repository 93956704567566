<template>
  <Header></Header>
  
  <div class="main-height">
    <section class="activated">
      <div class="container">
        <div class="inner-container">
          <div class="row">
            <div class="col-12">
              <h2 class="fw-bold">
                Welcome to ProCricket!
              </h2>
            </div>
          </div>
  
          <div class="row">
            <div class="col-12">
              <div class="text-3 mt-2 text-justify">
                <p>
                  You have started your trial and we emailed the details to your email <span class="fw-bold">{{this.email}}.</span>
                </p>
              </div>
            </div>
          </div>
  
          <div class="row">
            <div class="col-12 mt-2">
              <button class="btn text-white w-100 rounded-1" @click="doStartWatching">
                <span v-show="!loading">Start Watching</span>
                <div v-show="loading" class="button-loading">
                  <div class="spinner-border" role="status">
                    <span class="sr-only"></span>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  
  <Footer></Footer>

</template>

<script>
import {defineComponent} from 'vue'
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import UsersService from "@/services/users-service";
import AuthService from "@/services/auth-service";
import LeagueService from "@/services/league-service";

export default defineComponent({
  name: "Trial Activation Success",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      error: '',
      email: '',
      loading: false
    }
  },
  methods: {
   
    doSignOut() {
      AuthService.signOut().then(() => {
        this.$router.push('/signin')
      })
    },
    doStartWatching() {
      if (this.loading) return;
      this.loading = true;
      UsersService.getUserProfile().then(() => {
        LeagueService.getLeagueList().then(() => {
          this.$router.push('/events')
        })
      }).catch((error) => {
        this.error = 'Error: ' + error;
        this.loading = false;
      })
    }
  },
  beforeMount() {
    UsersService.getUserProfile()
    // prevent direct navigation bugs
    if (localStorage.getItem('userProfile') == null) {
      AuthService.signOut().then(() => {
        this.$router.push('/signin')
      })
    }
    if (localStorage.getItem('userProfile')) this.email = JSON.parse(localStorage.getItem('userProfile')).email
  },
})
</script>

<style>
@import '../assets/style.css';
</style>