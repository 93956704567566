import apiClient from '@/utils/api-client'

import {League, Event} from "@/model/models";

class LeagueService {
    public getLeagueList() {
        return new Promise<boolean>((resolve, reject) => {
            apiClient.get('/api/v1/leagues').then((res) => {
                const list: League[] = [];
                const leagueList = res.data.leagueList
                for (const x in leagueList){
                    if (leagueList[x].active) {
                        list.push(new League(leagueList[x].leagueId,leagueList[x].caption,leagueList[x].description,leagueList[x].logoFilename,
                            leagueList[x].logoAltText,leagueList[x].application,leagueList[x].status,leagueList[x].active, sortByOrderNumber(leagueList[x].events)))
                    }
                }
                function sortByOrderNumber(events: Event[]): Event[] {
                    // Create a new sorted array without modifying the original array
                    const sortedEvents = [...events];
                    sortedEvents.sort((a, b) => {
                        const orderNumberA = a.orderNumber || 0;
                        const orderNumberB = b.orderNumber || 0;
                        return orderNumberA - orderNumberB;
                    });
                    return sortedEvents;
                }
                localStorage.setItem('leagueList', JSON.stringify(list))
                resolve(true)
            }).catch((error) => {
                reject(error)
            })
        })
    }
}

export default new LeagueService()
