<template>
<footer class="footer bg-light">
    <div class="container-fluid">
        <div class="site-footer">
            <div class="footer-top">
                <h5>
                    Questions?
                    <a href="mailto:support@procricket.tv">
                        Contact Us.
                    </a>
                </h5>
            </div>
            <div class="footer__link__container">
                <a class="footer__link" role="button" href="https://procricket.help/faqs">FAQs</a>
                <a class="footer__link" role="button" href="https://procricket.help">Help Center</a>
                <a class="footer__link" role="button" @click="this.$router.push('/privacy-policy')">Privacy Policy</a>
                <a class="footer__link" role="button" @click="this.$router.push('/terms-of-service')">Terms of Service</a>
                <a class="footer__link" role="button" @click="this.$router.push('/cookie-policy')">Cookie Policy</a>
                <a class="footer__link" role="button" @click="this.$router.push('/acceptable-use-policy')">Acceptable Use Policy</a>
                <a class="footer__link" role="button" @click="this.$router.push('/refund-policy')">Refund Policy</a>
            </div>
        </div>
    </div>
</footer>
</template>

<script>
export default ({
    name: 'Footer'
})
</script>

<style>
@import '../assets/style.css';
</style>